import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from "@mui/material/Button";
export const JoinTheTeam = () => {
  const H2 = styled.h2`
      color: #FFFFFF;
   
    `

  const Div = styled.div `
  background-color: #2a3b8e;
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
`
  const DivImage = styled.div`
    background-image: url("img/footballpitch.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  absolute;
    z-index: -1;  
   
  `
  const Content = styled.div `
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         padding: 30px;
         align-items: left
         position: absolute;
         line-height: 22px;
   `
   const P = styled.p`
   font-size: 20px;
   line-height: 30px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
    `
    return <Div>
      <H2>Join the Team</H2>
    <Content>
    
        <P>Start your career as a site manager at Shields, providing you an opportunity to grow and learn at one of the UKs fastest growing site management companies.</P>

        <P>All applicants subject DBS clear checks and the right to work in the UK.</P>        
        
        </Content>
        <Link to="/contact_us"><Button sx={{color: 'white'}} variant="outlined">Make Enquiry</Button></Link>
  </Div>
  
}