import { Training } from "../compoents/about/training"
import { CompanyInfo } from "../compoents/about/companyInfo"
import { Community } from "../compoents/about/community"
import { Testimonials } from "../compoents/about/testimonials"
import { MeetTheTeam } from "../compoents/about/meetTheTeam"

export const About = () => {
  return (
    <div>      
      <CompanyInfo></CompanyInfo>      
      <MeetTheTeam></MeetTheTeam>
      <Training></Training>
      <Testimonials></Testimonials>
      <Community></Community>
    </div>
  )
}