import { SiteManagement } from "../compoents/services/siteManagement"
import { EmergencyServices } from "../compoents/services/emergencyServices"
import { SiteInspection } from "../compoents/services/siteInspection"
import { BespokeServices } from "../compoents/services/bespokeServices"

export const Services = () => {
    return (
      <div>
        <SiteManagement></SiteManagement>
        <EmergencyServices></EmergencyServices>
        <SiteInspection></SiteInspection>
        <BespokeServices></BespokeServices>
      </div>
    )
}