// import { Link } from 'react-router-dom'
import _styled from 'styled-components'
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BuildIcon from '@mui/icons-material/Build';
import  ListBullet  from '../utils/listBullet';



export const SiteManagement = () => {
  const styleMaxWidth = {
    maxWidth: "18rem"
  }
  const H2 = _styled.h2`
      color: #000000;
      @media only screen and (max-width: 412px) {
        &{
          font-size: 1.5rem;
        }
      }     
    `

  const Div = _styled.div`
  display: flex;
  flex-wrap: wrap; 
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 570px) {
    &{
      align-items: baseline;
    }
  }  
`
  const BulletList = _styled.div`  
  @media only screen and (min-width: 1500px) {
    &{
      
    }
  }
`

  const DivImage = _styled.div`
    background-image: url("img/padlock1.webp");
    background-repeat: no-repeat;
    background-position: top-right;   
    background-size: cover;
    opacity: 0.4;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  relative;
    z-index: -1    
    `  
      const Ul = _styled.ul`
      padding: 5px;
      list-style: none;     
      @media only screen and (max-width: 412px) {
        &{
          padding: 2px;
        }
      }
      @media only screen and (max-width: 370px) {
        &{          
          padding: 0px;
        }
      }
    `
  const P = _styled.p`
      font-size: 16px;
      line-height: 20px;
      padding: 10px;
      @media only screen and (max-width: 460px) {
        &{
          
          line-height: 15px;
          font-size: 12px;
        }
      }
      `

  const Content = _styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; 
  padding: 30px;
  position: absolute;         
   `
  return <Div>

    <DivImage></DivImage>

    <Content>
      
    <H2>Site Managment</H2>
      <div>
        <P>School Site managers have an essential role in the smooth running of schools. Their duties are varied and sometimes complex so a good understanding of protocols and procedures is important.
          The health and safety of children and staff is a major priority and the care of buildings and school grounds are a daily requirement. The reporting dangerous occurrences or potential hazards are routine.
          Shields focuses much on making sure it’s staff are up to date with changes to working practices and procedures and provides training whenever the need arises. All staff are enhanced DBS checked and have clearance to work in the UK.
          Our staff work to the highest standards and are polite and able to adjust to changes in school environments.
        </P>
      </div>
      <Grid container spacing={3}>
      
        <Grid item xs={12} md={4} lg={5}>
          <Ul>
            <ListBullet text={"Securing site before opening of school"}/>
            <ListBullet text={"Security observations during morning arrivals"}/>
            <ListBullet text={"Securing school for start of day"}/>
            <ListBullet text={"Securing school for closure"}/>
          </Ul>
          </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Ul>
            <ListBullet text={"Opening of school"}/>
            <ListBullet text={"Gate opening"}/>
            <ListBullet text={"Day to day operational help for teachers and staff"}/>
            <ListBullet text={"Preparing school for home time"}/>
            <ListBullet text={"Closure of school"}/>
          </Ul>        
          </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Ul>
          <ListBullet text={"Emergency door checks"}/>
          <ListBullet text={"Light and heating checks"}/>
          <ListBullet text={"Playground and wider site clearance"}/>
          <ListBullet text={"Cleaner supervision"}/>
          <ListBullet text={"Contractor and other off site 3rd party facilitation"}/>
        </Ul>
        </Grid>      
     </Grid>
    
  </Content>
  </Div >
}