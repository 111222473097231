import styled from 'styled-components'
export const MeetTheTeam = () => {

  const Div = styled.div `
  background-color: #2a3b8e;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`

  const H2 = styled.h2`
        color: #FFFFFF;
    
      `
  const H4 = styled.h4`
    color: #FFFFFF;

  `
  const P = styled.p`
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 570px) {
          &{
            line-height: 15px;
            font-size: 12px;
          }
        }
      `
  const Content = styled.div `
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          padding: 20px;
          align-items: center;         
        `

  const Span = styled.span `
  line-height: 20px;
    display: block;
    max-width: 400px;
    margin: 0 auto;    
  `
  const I = styled.i`
  line-height: 80px;
  `
    return  <Div>
   
        <Content>
          <H2>Meet The Team</H2>
          
          <Span className="fa-stack fa-3x">
            <I className="fas fa-school fa-stack-1x fa-inverse"></I>
          </Span>          
            <H4>Founder and Manager Director</H4>          
            <P>Michael Douglas has a successful track record of leading customer first companies having founded and operated multipule business over a 4 decade long career. Having worked with local councils since 1981, the light blub moment came in 2011 when working for Enfield council in the site management department; this was the birth of Shields, a one stop shop for site management cover and relief for schools not covered by the council. </P>
        
        
            <Span className="fa-stack fa-3x">            
              <I className="fas fa-tools fa-stack-1x fa-inverse"></I>
            </Span>          
              <H4>Operational Manager</H4>          
              <P>Alan brings a wealth of customer services experience gained over the last 30 years in various sectors including Local Government, National Retail, International Importing and UK Commercial Property. With a focus on ensuring that customers receive the very best in care and attention to detail, Alan ensures that all staff are fully kept up to date with the latest training requirements and is often the first point of contact to onboard new customers to the Shields client family.</P>
         
        
            <Span className="fa-stack fa-3x">
              <I className="fas fa-lock fa-stack-1x fa-inverse"></I>
            </Span>          
              <H4>Company secretary</H4>
              <P>With a background in customer service dating back to 1979 and spanning 21 years, Lorna brings a wealth of knowledge to the customer experience at Shields. Lorna also spent 19 years within education, allowing her to liaise with schools from a unique point of view making administration painless.</P>
        
     
          <Span className="fa-stack fa-3x">
            <I className="fas fa-leaf fa-stack-1x fa-inverse"></I>
          </Span>          
            <H4>Site Managers</H4>         
          <P>Shields focuses on making sure it’s staff are up to date with changes to working practices and procedures and provides training whenever the need arises.<br/> All staff are enhanced DBS checked and have clearance to work in the UK. <br/>
              Our staff work to the highest standards and are polite and able to adjust to changes in school environments. 
          </P>
        
</Content>
  </Div>
}