import styled from 'styled-components'
import  ListBullet  from '../utils/listBullet';

export const EmergencyServices = ({handleScroll}) => {

const H2 = styled.h2`
      color: #FFFFFF;
   
    `

  const Div = styled.div `
      background-color: #2a3b8e;
      display: flex; 
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    `
  const DivImage = styled.div`
    background-image: url("img/firepanel.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  absolute;
    z-index: -1;   
  `
  const Content = styled.div `
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: center;  
    flex-direction: column;
    align-items: center;
   `

   const Li = styled.li`
   font-size: 20px;
   line-height: 50px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
  `
  const Ul = styled.ul`
  padding: 10px;
  list-style: none;
  @media only screen and (max-width: 412px) {
    &{
      padding: 5px;
    }
  }
`
    return <Div>        
    <Content> 
    <a href="https://www.flaticon.com/free-icons/alarm" title="Alarm icons created by Freepik - Flaticon"> 
      <img alt="alarm" src="img/alarm.png" style={{"width": "50px"}}></img>
      </a>
    <H2>Emergency Services</H2>    
      <Ul>
        <ListBullet text={"Alarm call outs 24/7"}/>
        <ListBullet text={"Unplanned site manager cover"}/> 
      </Ul>      
    </Content>   
  </Div>
}