import styled from 'styled-components'
import Button from "@mui/material/Button";
export const Community = () => {
  const H2 = styled.h2`
      color: #000000;
   
    `

  const Div = styled.div `
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 412px) {
    &{      
      align-items: baseline;
    }
  }  

`
  const DivImage = styled.div`
    background-image: url("img/footballpitch.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  relative;
    z-index: -1;  
   
  `
  const Content = styled.div `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;    
    padding: 30px;
    position: absolute;

   `
   const P = styled.p`
   font-size: 20px;
   line-height: 30px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
    `
  return <Div>
    <DivImage></DivImage>
  <Content>
        <H2 className="section-heading text-uppercase">Community</H2>
            <P>Coming Soon!</P>    
  </Content>  
      </Div>
}
