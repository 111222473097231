import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BuildIcon from '@mui/icons-material/Build';
import  ListBullet  from '../utils/listBullet';
export const CompanyInfo = () => {

  const H2 = styled.h2`
      color: #000000;
      margin: auto;
    `

  const Div = styled.div `
  display: flex; 
  align-items: center;  
  flex-wrap: wrap;
  @media only screen and (max-width: 412px) {
    &{
      flex-direction: column;
      align-items: baseline;
    }
  }  
`
  const DivImage = styled.div`
    background-image: url("img/schoolgrounds2.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  relative;
    z-index: -1;  
   
  `
  
  const Content = styled.div `
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         padding: 30px;
         position: absolute;
        `
   const P = styled.p`
      font-size: 20px;
      line-height: 30px;
      
      @media only screen and (max-width: 570px) {
        &{
          font-size: 15px;
        }
      }
      @media only screen and (max-width: 420px) {
        &{          
          line-height: 20px;
        }
      }
      @media only screen and (max-width: 380px) {
        &{          
          font-size: 12px;
        }
      }
    `    
      const Ul = styled.ul`
        padding: 10px;
        list-style: none;
        @media only screen and (max-width: 412px) {
          &{
            padding: 5px;
          }
        }
      `
  
  return <Div>
        <DivImage></DivImage>
        <Content>
        <H2>About</H2>
          <P>Shields Premises Personnel is a privately owned site management company,
            established in 2011 specialising in school site management cover and relief.
            Our data driven and customer first approach empowers sustainable and cost effective solutions.
          </P>

          <P>The team at Shields brings over 35 years of education sector experience to the table, and understands the unique challenges
            associated with running a school from a site management perspective as well as an internal operational stand point.
            Founded by site managers with local and national experience, Shields is a one stop shop service that customers can access 24 hours a day, 7 days a week and 365 days a year.
          </P>

          <P>Services are carefully packaged to meet the unique needs of every customer, focusing on 3 core principles: </P>
            < Ul>
              <ListBullet text={"Consistency in providing the highest level of service"}/>
              <ListBullet text={"Availability to trusted services within strict targeted timeframes"}/>
              <ListBullet text={"Trust, we pride ourselves on our ability to carry out a professional service, where the safety of children and staff is put first"}/>              
            </ Ul>
            
        </Content>
        
  </Div>
  }
  