import styled from "styled-components";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export const Footer = () => {

    const FooterContainer = styled.div`
      display: flex;
      margin: auto 0;

    `

    const Div = styled.div `        
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5px;
  `
  const Content = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
  flex-direction: row;
 `
 const P = styled.p`
    font-size: 10px;
    margin-top: inherit;
    margin-bottom: 0rem;
   @media only screen and (max-width: 570px) {
     &{
       
       font-size: 10px;
     }
   }
`
const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Contact = styled.div`
    display: flex;
    flex-direction: column;  
    @media only screen and (max-width: 899px) {
        &{
            align-items: center;
            justify-content: center;
        }
      }
    
`
const Address = styled.div`
    display: flex;
    flex-direction: column;  
    @media only screen and (max-width: 899px) {
        &{
            align-items: center;
            justify-content: center;
        }
      }
`

const PirvacyPolicy = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
    return <Div>
       
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid xs={12} sm={12} md={3} lg={4}>
                    <Logo>
                        <img height={30} width={30} alt="logo" src="img/logos/spplogo.webp">
                        </img>
                        <P>Caretaking Solutions</P>
                        
                    </Logo>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={4}>
                    <Contact>
                        <span><LocalPhoneIcon sx={"font-size: 12px"}></LocalPhoneIcon> 02082455135</span>
                        <span><AlternateEmailIcon sx={"font-size: 12px"}></AlternateEmailIcon> enquiry@shieldspremisespersonnel.co.uk</span>
                    </Contact>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={3}>
                    <Address>
                        <P className="copyright"> &copy; Shields Premises Personnel {new Date().getFullYear()} </P>
                        <P> | 10a High Road, Chislehurst Kent, England, BR7 5AN </P>
                    </Address>

                </Grid>
                <Grid xs={12} sm={12} md={3} lg={1}>
                    <PirvacyPolicy>
                        <a href="https://www.iubenda.com/privacy-policy/87035273" className="iubenda-white iubenda-embed"
                           title="Privacy Policy ">Privacy Policy</a>
                    </PirvacyPolicy>

                </Grid>
            </Grid>

            
    </Div>


}

