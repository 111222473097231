import styled from 'styled-components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BuildIcon from '@mui/icons-material/Build';
import { useState, useEffect } from 'react';
import { margin } from '@mui/system';

export default function ListBullet ({ text }) {

    const [listItemText, setListItemText] = useState('')
    const Li = styled.li`
      display: flex;
      font-size: 15px;
      margin-left: 10px;
      line-height: 35px;      
      text-align: left;
      @media only screen and (max-width: 899px) {
        &{          
          line-height: 20px;          
        }
      }
      @media only screen and (max-width: 412px) {
        &{
          font-size: 12px;
        }
      }
      @media only screen and (max-width: 370px) {
        &{
          line-height: 12px;
          font-size: 10px;
        }
      }
  `
  const SpanIcon = styled.span`
  margin-right: 20px;
  text-align: left;
  @media only screen and (max-width: 570px) {
    &{
        margin-right: 10px;
    }
  }  
  `

  const SpanText = styled.span`  
  text-align: left;
  @media only screen and (max-width: 570px) {
    &{
       
    }
  } 
  `


    useEffect(() => {
        if (text) {
            setListItemText(prevState => {
                prevState = text
                return prevState
            })
        }

    }, [text])

    return (
        <Li> <SpanIcon><BuildIcon ></BuildIcon></SpanIcon> <SpanText>{text}</SpanText> </Li>
    )
}