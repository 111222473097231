import { useState } from "react";
import styled from 'styled-components'

export const Contact = () => {
  const [showThankYou, setShowThankYou] = useState(false);

    const handleFormSubmit = async (event) =>  {
        event.preventDefault()

        const name = document.getElementById("name").value
        const email = document.getElementById("email").value
        const phone = document.getElementById("phone").value
        const message = document.getElementById("message").value
        const emailObject = {name, email, phone, message}
        const endpoint = "https://oldgu9kyzj.execute-api.eu-west-1.amazonaws.com/prod/email"
        const emptyObjects = Object.keys(emailObject).filter(key => {
            if(emailObject[key] === '') {
                return {key}
            }
        })
        const isValidEmail = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)?.length || 0
        const isValidPhone = phone.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)?.length || 0

        if(!emptyObjects.length && isValidEmail && isValidPhone) {
              Object.keys(emailObject).forEach(id => {
                  document.getElementById(id).style.removeProperty("border-color")
              })
            await fetch(endpoint, {
                method: "POST",
                headers : {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(emailObject)
            })
            showThankYouMessage(); // Show the thank you message
          } else {
            if (!isValidEmail) {
              const element = document.getElementById("email");
              element.style.borderColor = `#dc3545`;
            }
      
            if (!isValidPhone) {
              const element = document.getElementById("phone");
              element.style.borderColor = `#dc3545`;
            }
      
            emptyObjects.forEach((id) => {
              const element = document.getElementById(id);
              element.style.borderColor = `#dc3545`;
            })
          }
        }

        const showThankYouMessage = () => {
          setShowThankYou(true);
          setTimeout(() => {
            window.location.reload(); // Refresh the page
          }, 5000); // 5 seconds
        };

        const P = styled.p`
   font-size: 20px;
   line-height: 30px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
    `

    return  <section className="section" id="contact">
      <div className="container">
       {showThankYou ? (
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Thank You For Your Enquiry!</h2>
              <P>Your message has been sent successfully.</P>
            </div>
          </div>
        ) : (
          <div>

        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Contact Us</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <form id="contactForm" name="sentMessage"  noValidate="novalidate" onSubmit={handleFormSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input className="form-control" id="name" type="text" placeholder="Your Name *" required="required"
                      data-validation-required-message="Please enter your name."/>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group">
                    <input className="form-control" id="email" type="email" placeholder="Your Email *" required="required"
                      data-validation-required-message="Please enter your email address."/>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group">
                    <input className="form-control" id="phone" type="tel" placeholder="Your Phone *" required="required"
                      data-validation-required-message="Please enter your phone number."/>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea className="form-control" id="message" placeholder="Your Message *" required="required"
                      data-validation-required-message="Please enter a message."></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-lg-12 text-center">
                  <div id="success"></div>
                  <input value={"Send Message"} id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" type="submit"></input>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
        )}
      </div>
    </section>
    
}