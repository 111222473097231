import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from "@mui/material/Button";
export const SHeader = () =>  {

    const Div = styled.div `
        display: flex;
        background-color: #2a3b8e;
        height: 100vh;
        flex-wrap: wrap;               
        flex-direction: column;
        @media only screen and (min-width: 571px) {
          &{
            align-content: stretch;
            justify-content: space-evenly;
          }
        }
        @media only screen and (max-width: 570px) {
          &{
            align-content: flex-start; 
            justify-content: space-around;
          }
        }
    `

    const Title = styled.div `
      display: flex;
      flex-direction: column;
      margin-left: 54px;
      justify-content: center;
      font-weight: 700;
      line-height: 0px;
    `

    const Span = styled.span `
       color: #f59120;

    `

    const H2 = styled.h2`
      color: #FFFFFF;
      font-size: 80px;
      @media only screen and (max-width: 570px) {
        &{
          font-size: 40px;
        }
      }
      @media only screen and (max-width: 412px) {
        &{
          font-size: 35px;
        }
      }
   
    `

    const P = styled.p`
      font-size: 50px;
      @media only screen and (max-width: 570px) {
        &{
          font-size: 25px;
        }
      }
      @media only screen and (max-width: 412px) {
        &{
          font-size: 20px;
        }
      }
    `

    const Content = styled.div `
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         align-content: stretch;
         align-items: center;
         margin-top: 110px;
      @media only screen and (max-width: 1000px) {
        &{
          margin-top: 0px;
          margin-left: 46px;
        }
      }
   
      
    `

    return <Div>
            <Title className="fadeInAnimated">
                <H2><Span>S</Span>HEILD</H2>
                <H2><Span>P</Span>REMISES</H2>
                <H2><Span>P</Span>ERSONNEL</H2>
                <P>Caretaking Solutions</P>
            </Title>            
        <Content className="fadeInAnimated">           
            <Link to="/contact_us"><Button sx={{color: 'white'}} variant="outlined">Make Enquiry</Button></Link>
        </Content>
    </Div>

  //
  //   <header className="section" id="page-top">
  //       <div className="">
  //           <div className="align-items-center">
  //               <div className="intro-text fadeInAnimated">
  //                   <div className="intro-heading text-uppercase">Shields</div>
  //                   <div className="intro-heading text-uppercase">Premises</div>
  //                   <div className="intro-heading text-uppercase">Personnel</div>
  //               </div>
  //               <div className="row mt-5">
  //               <div className="Col-4 intro-tagline mt-5">
  //                   <p>Caretaking Solutions</p>
  //                   <div className="row justify-content-center">
  //                       <Link to="/contact"><button className="btn btn-outline-light btn-lg text-uppercase ">Make Enquiry</button></Link>
  //                   </div>
  //               </div>
  //               </div>
  //           </div>
  //       </div>
  // </header>

}