import styled from 'styled-components'
import  ListBullet  from '../utils/listBullet';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
export const SiteInspection = () => {
  const H2 = styled.h2`
      color: #000000;
      margin: auto;
   
    `

  const Div = styled.div `
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 412px) {
      &{
        flex-direction: column;
        align-items: baseline;
      }
    }
`
  const DivImage = styled.div`
    background-image: url("img/firepanel.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.4;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  relative;
    z-index: -1;  
   
  `
  const Content = styled.div `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px;
    position: absolute;
   `

   const Li = styled.li`
   font-size: 20px;
   line-height: 50px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
  `
  const Ul = styled.ul`
    padding: 10px;
    list-style: none;
    @media only screen and (max-width: 412px) {
      &{
        padding: 5px;
      }
    }
  `
      const P = styled.p`
      font-size: 20px;
      line-height: 30px;
        @media only screen and (max-width: 570px) {
          &{
            line-height: 20px;
            font-size: 12px;
          }
        }
    `
    return  <Div>
     
      <Content>   
      <H2>Site Inspection</H2>    
    
      <P><b>Site management consultancy gap reports, site inspections £799.99 ex VAT</b><br/>
          Receive a traffic light report outlining your current risk and areas of improvement with action plan.</P>   
      
      <Box sx={{ flexGrow: 2, Width: "auto" }}>
       <Grid container spacing={2}>     
        <Grid item xs={12} md={6}>
          <Ul>
          <ListBullet text={"Understand the current state of your site"}/>
          <ListBullet text={"How safe is your site Health and safety"}/>
          <ListBullet text={"Are you meeting basic compliance"}/>
          <ListBullet text={"Understand risk"}/>
          </Ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <Ul>
          <ListBullet text={"Cost cutting opportunities"}/>
        <ListBullet text={"Required maintenance"}/>
        <ListBullet text={"Up to 2 days on site"}/>
        <ListBullet text={"Report reviewing meeting"}/>
          </Ul>        
      </Grid>      
     </Grid>
     </Box>         
   
    </Content>
    <DivImage></DivImage>
  </Div>
}