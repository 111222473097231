import "./css/spp_stylesheet.css"
import "./vendor/bootstrap/css/bootstrap.min.css"
import "./vendor/fontawesome-free/css/all.min.css"
import React from 'react'
import {Route, Routes} from 'react-router'
import {BrowserRouter} from 'react-router-dom'
import { Nav } from './compoents/nav'
import { SHeader } from "./compoents/hero"
import { About } from "./Cotainers/about"
import { JoinTheTeam } from "./compoents/joinTheTeam"
import { Services } from "./Cotainers/services"
import { Contact } from "./compoents/contact"
import {Footer} from "./compoents/footer";

function App() {
  return (
    <BrowserRouter>
        <Nav></Nav>
        <Routes>
            <Route exact path="/" element={<SHeader />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services/>}/>
            <Route path="/Join_The_Team" element={<JoinTheTeam/>} />
            <Route path="/Contact_Us" element={<Contact/>} />
        </Routes>
        <Footer/>
    </BrowserRouter>

  );
}

export default App;
