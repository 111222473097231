import styled from 'styled-components'
import * as React from 'react';
import { experimentalStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
export const Testimonials = () => {

  const Item = experimentalStyled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
    const styleMaxWidth = {
        maxWidth : "18rem"
    }    

    const Div = styled.div `    
      background-color: #2a3b8e;
      display: flex; 
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @media only screen and (max-width: 570px) {
        &{
          flex-direction: column;
          align-items: baseline;
        }
      }  
  `    
  

    const P = styled.p`
      font-size: 16px;
      line-height: 30px;
      @media only screen and (max-width: 570px) {
        &{
          
          font-size: 12px;
        }
      }
      @media only screen and (max-width: 412px) {
        &{
          
          font-size: 10px;
        }
      }
    `

const H2 = styled.h2`
color: #FFFFFF;
padding: 50px;
@media only screen and (max-width: 570px) {
  &{
    
    padding: 25px;
  }
}
@media only screen and (max-width: 412px) {
  &{
    
    padding: 0px;
  }
}

`

const H3 = styled.h3`
color: #000000;
padding: 50px;
@media only screen ad (max-width: 570px) {
  &{
    
    padding: 25px;
  }
}
@media only screen and (max-width: 412px) {
  &{
    
    padding: 0px;
  }
}

`


const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: center;         
   `

    return (
      <Div>
      <Content>
        <H2>Customer Testimonials</H2>
      
        <Grid container spacing={{ xs: 4, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={6}>
              <Item>
                <H3> St. Paul and St. Martin of Porres Catholic Primary Schools </H3>
                <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>
                  <P>As a Head of School in an Inner London primary, with over 35 years teaching experience across secondary and primary,
                     I can say without reservation that the team at Shield Premises Personnel provide a fantastic service.</P>

                  <P>Five years ago in 2018 we approached Shield's for support with our site services management.
                    In the past five years we have had exceptional service. The staff have been punctual and professional at all times.</P>

                  <P>They are always ready to go the extra mile to support the school. The loyalty and commitment shown by staff has been exceptional.
                      The management of staff and flexibility to support the school has been fantastic. During the past 5 Years,
                      the school has had the misfortune of two extended periods of major building works.
                      The team at Shield’s were incredible in supporting the school to ensure that the works took place with as little disruption as possible.</P>

                    <P>Taking into consideration the experience we have working collaboratively with Michael the director and Charles our site services manager, I can recommend Shield Premises Personnel, whole heartedly with no reservation.
                       If you need school site services personnel support just give them a call you will not be disappointed.</P>

                    <P>Pete O'Shannessy</P>

                    
                <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div> 
              </Item>
            </Grid>
            { <Grid item xs={4} sm={4} md={6}>
              <Item>
              <H3> Bounds Green School </H3>              
                <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>
                
                <P>We have worked with Shields for a number of years. Shields came to support the school due to long term
                  absences within our site team.</P>

                  <P>Shields were invaluable to us during lockdown. Shields assisted and offered expert advice on how we could
                      implement the government’s forever changing Covid guidelines.</P>

                  <P>We have benefitted from the same small team of relief site staff for the period of time we have worked with
                    Shields. This has meant that any of the relief site support Shields sends to us understands the requirements
                        and setup of our large school site, which comprises of two federated schools.</P>

                    <P>All members of Shields are friendly, reliable and efficient. They have become valued and recognised members
                            of our school community to both staff and children.</P>

                    <P>Shields has shown total commitment to our school. A member of Shields has even joined our school’s ‘Green
                        Action’ working party in which we are working on improving the school’s physical environment and becoming
                        a more environmentally friendly school.</P>

                    <P>We would recommend Shields without reservation as relief site support.</P>

                    <P>Faye Papini</P>
                    <P>School Business Manager</P>
                <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div>
              </Item>
            </Grid> }
           {/*  <Grid item xs={4} sm={8} md={4}>
              <Item>
              <H3> St. Paul and St. Martin of Porres Catholic Primary Schools </H3>

                <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>           
                <P>As a Head of School in an Inner London primary, with over 35 years teaching experience across secondary and primary,
                     I can say without reservation that the team at Shield Premises Personnel provide a fantastic service.</P>

                  <P>Five years ago in 2018 we approached Shield's for support with our site services management.
                    In the past five years we have had exceptional service. The staff have been punctual and professional at all times.</P>
                <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div>
              </Item>
            </Grid> */}
          </Grid>
      
      </Content>
      </Div>
    );
    
    
    {/* <Section>
      <Container>
<div className="container mt-5 mx-auto">

      <div className="row">

        <div className="col-lg-12 text-center">
          <h2 className="section-heading sh-black text-uppercase">Customer Testimonials</h2>          
        </div>
      </div>

      <div className="row text-center">

        <div className="card border-light mt-5  mx-3 mx-auto" style={styleMaxWidth}>
          <div className="card-header">School 1</div>          
          <div className="card-body text-dark"> 
            <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>
            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure expedita earum architecto exercitationem officiis ut, voluptatibus pariatur quae nostrum quaerat incidunt sed fuga, natus, doloribus magni voluptatum non minus magnam!</p>
            <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div>            
          </div>
        </div>

        <div className="card border-light mt-5 mx-3 mx-auto" style={styleMaxWidth}>
          <div className="card-header">School 2</div>
          <div className="card-body text-dark">            
            <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>
            <p className="card-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis cum necessitatibus corrupti iusto! Fugiat consequatur eius eveniet inventore libero nisi quae non, repellendus amet, deleniti animi at nulla adipisci soluta.</p>
            <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div>
          </div>
        </div>

        <div className="card border-light mt-5 mx-3 mx-auto" style={styleMaxWidth}>
          <div className="card-header">School 3</div>
          <div className="card-body text-dark">
            <div className="alignLeft"><i className="fas fa-quote-left fa-1x"></i></div>           
            <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia sed ullam nesciunt possimus excepturi deserunt culpa et quidem sunt rem reprehenderit, est eveniet consequuntur repellendus pariatur eos beatae eius officiis?</p>
            <div className="alignRight"><i className="ml-5 fas fa-quote-right fa-1x"></i></div>
          </div>
        </div>

      </div>
      </div>
      </Container>
<DivImage></DivImage>

</Section> */}
}