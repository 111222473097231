import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from "@mui/material/Button";
import  ListBullet  from '../utils/listBullet';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const BespokeServices = () => {
  const H2 = styled.h2`
      color: #FFFFFF;
   
    `

  const Div = styled.div `
    background-color: #2a3b8e;
    height:100vh;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  `
  const DivImage = styled.div`
    background-image: url("img/firepanel.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  absolute;
    z-index: -1;  
   
  `
  const Content = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  
    flex-direction: row;
   `

   const Li = styled.li`
   font-size: 20px;
   line-height: 40px;
     @media only screen and (max-width: 570px) {
       &{
         line-height: 20px;
         font-size: 12px;
       }
     }
  `
  const Ul = styled.ul`
        padding: 10px;
        list-style: none;
        @media only screen and (max-width: 412px) {
          &{
            padding: 5px;
          }
        }
      `
    return  <Div>
      <Content>
      <H2>Bespoke Services</H2>  
       <Grid container spacing={3}>     
        <Grid item xs={12} md={4} lg={5}>
          <Ul>
            <ListBullet text={"General maintenance work"}/>
            <ListBullet text={"Pluming"}/>
            <ListBullet text={"Emergency door checks"}/>
            <ListBullet text={"Electrician work"}/>
          </Ul>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Ul>
            <ListBullet text={"Carpentry"}/>
            <ListBullet text={"Cleaning services"}/>
            <ListBullet text={"Painting"}/>
            <ListBullet text={"Guttering"}/>          
          </Ul>        
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
          <Ul>
            <ListBullet text={"Aerial site photography"}/>
            <ListBullet text={"Site photography"}/>
            <ListBullet text={"Security"}/>
          </Ul>        
      </Grid>           
     </Grid>
           
    
      <Link to="/contact_us"><Button sx={{color: 'white'}} variant="outlined">Make Enquiry</Button></Link>
    </Content>
  </Div>
}