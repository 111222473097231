import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from "@mui/material/Button";
import  ListBullet  from '../utils/listBullet';
export const Training = () => {

  const H2 = styled.h2`
      color: #000000;
   
    `

  const Div = styled.div `
    display: flex;  
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`
  const DivImage = styled.div`
    background-image: url("img/firepanel.webp");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover;
    opacity: 0.2;
    filter: blur(4px);
    height:  800px;
    width: 100%;
    position:  relative;
    z-index: -1;  
   
  `
  const Content = styled.div `
         display: flex;
         flex-wrap: wrap;
         flex-direction: column;
         align-items: center;
         padding: 30px;
         position: absolute;
   `
   
  const Ul = styled.ul`
  padding: 10px;
  list-style: none;
  @media only screen and (max-width: 412px) {
    &{
      padding: 5px;
    }
  }
`

    return <Div>
      <DivImage></DivImage>
      <Content>
                <H2>Training site managers receive</H2>
        
          <Ul>
          <ListBullet text={"Advanced DBS clearance"}/>
          <ListBullet text={"Safe guarding by KEY, keeping children safe in education"}/>
          <ListBullet text={"Health and safety trained by SL Safesmart CPD"}/>
          <ListBullet text={"Fire warden by Safesmart CPD"}/>
          <ListBullet text={"COSHH Awareness by Safesmart CPD"}/>
          <ListBullet text={"Working at heights by Safesmart CPD"}/>
        </Ul>

          <Link to="/contact_us"><Button sx={{color: 'black'}} variant="outlined">Make Enquiry</Button></Link>
          
      </Content>
      
    </Div>   
  
}